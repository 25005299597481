(function($) {

	var $headings = $('.section-header-heading:not([data-toc-ignore])'),
		$menu = $('.js-jump-links');

	$.each($headings, function(key, value) {
		var $this = $(this),
			text = $this.text().replace('#', ''), 
			// slug = slugify(text);

			slug = $this.data('heading-id');

			console.log(text);
			console.log(slug);

		// $this.attr('id', slug);

		var $elem = $('<a/>');
		$elem.attr('href', '#' + slug);
		$elem.text(text);
		$elem.addClass('jump-link');
		$menu.append( $elem ).parents('.sidebar').addClass('show');
	});

})(jQuery);