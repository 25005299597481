(function($) {

	var $component = $('.js-case-studies'),
		$caseStudies = $('.js-case-study'),
		$caseStudiesImages = $('.js-case-study-image');

	if ( $caseStudies.length ) {

		$caseStudies.on('mouseenter', 'a', function() {
			var $this = $(this).parent(),
				caseStudyID = $this.data('case-study').toString();

			console.log( caseStudyID, '[data-case-study="' + caseStudyID + '"]', $caseStudiesImages.has('[data-case-study="' + caseStudyID + '"]') );

			var $image = $('.js-case-study-image[data-case-study="' + caseStudyID + '"]');
			if ( $image.length ) {
				$image.addClass('visible');
				$component.addClass('visible');
			}
		}).on('mouseleave', 'a', function() {
			$caseStudiesImages.removeClass('visible');
			$component.removeClass('visible');
		});
	}

})(jQuery);
